import React from 'react';
import moment from 'moment';
import { useLang } from '../../context';
import { translate } from '../../translations';
import * as styles from './PublishedOn.module.scss';

const PublishedOn = ({ date }) => {
  const lang = useLang();

  return (
    <span className={styles.date}>
      {translate(lang, 'post.publishedOn')} {moment(date).format("DD.MM.YYYY")}
    </span>
  );
};

export default PublishedOn;
