import React from 'react'
import Img from 'gatsby-image'
import * as styles from './CoverWithTitle.module.scss'

const CoverWithTitle = ({ title, featuredImage, featuredImageSrc, isPreview, isFullPost }) => {
  const Header = isFullPost ? 'h1' : 'h2';

  return (
    <div>
      <Header className={styles.title}>{title}</Header>
      {isPreview && <img className={styles.cover} src={featuredImageSrc} />}
      {!isPreview && <Img className={styles.cover} fluid={featuredImage.childImageSharp.fluid} />}
    </div>
  );
};

export default CoverWithTitle
